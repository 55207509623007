jQuery(document).ready(function() {

    // validate signup form on keyup and submit
    $("#applyForm").validate({
        rules: {
            fname: "required",
            lname: "required",
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            firstname: "Please enter your firstname",
            lastname: "Please enter your lastname",
            email: "Please enter a valid email address"
        }
    });


    // validate preorder form on keyup and submit
    $("#preorderForm").validate({
        rules: {
            fname: "required",
            lname: "required",
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            firstname: "Please enter your firstname",
            lastname: "Please enter your lastname",
            email: "Please enter a valid email address"
        }
    });


    // validate signup form on keyup and submit
    $("#newsletterForm").validate({
        rules: {
            newsletterEmail: {
                required: true,
                email: true
            }
        },
        messages: {
            newsletterEmail: "Please enter a valid email address"
        }
    });


    $("#newsletterForm").submit(function(e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.
        if ($("#newsletterForm").valid()){
            var url = 'wp/wp-admin/admin-ajax.php';
            $.ajax({
                type: "POST",
                url: url,
                data: $("#newsletterForm").serialize(),  // serializes the form's elements.
                success: function(response) {
                    window.location = document.location.origin + response;
                } 
            });
        }
    });

    $("#applyForm").submit(function(e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.
        if ($("#applyForm").valid()){
            var url = 'wp/wp-admin/admin-ajax.php';
            $.ajax({
                type: "POST",
                url: url,
                data: $("#applyForm").serialize(), // serializes the form's elements.
                success: function(response) {
                    window.location = document.location.origin + response;
                } 
            });

        } else {
            $('#Modal01').modal('handleUpdate');
        }
    });

    $("#preorderForm").submit(function(e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.
        if ($("#preorderForm").valid()){
            var url = 'wp/wp-admin/admin-ajax.php';
            $.ajax({
                type: "POST",
                url: url,
                data: $("#preorderForm").serialize(), // serializes the form's elements.
                success: function(response) {
                    window.location = document.location.origin + response;
                } 
            });

        } else {
            $('#Modal01').modal('handleUpdate');
        }
    });
});
